
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ContainerEvent from "@/models/ContainerEvent.model";
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import {EventStatus} from "@/enum/EventStatus.enum";
import Company from "@/models/Company";
import {ROUTE_COMPANY, ROUTE_STORE} from "@/router/routes";
import {CompanyStoreActions, CompanyStoreMutations} from "@/store/company.store";
import {APPLICATION_STORE_NAME, ApplicationStoreMutations} from "@/store/application.store";
import {namespace} from "vuex-class";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component
export default class CompanyListComponent extends Vue {
  @Prop({ required: true })
  public companies!: Company[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_COMPANY)
  private setCurrentCompany!: (company: Company) => void;

  /**
   * headers of the table
   */
  private headers = [
    { text: this.$t('COMPANY.NAME'), align: 'start', sortable: true, value: 'name' },
    { text: this.$t('COMPANY.PHONE'), align: 'start', sortable: true, value: 'contact.phone' },
    { text: this.$t('COMPANY.EMAIL'), align: 'start', sortable: true, value: 'contact.email' },
    //  TODO: check how to get the sum of microservices
    { text: this.$t('COMPANY.MICROSERVICE'), align: 'start', sortable: true, value: '-' }
  ];

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  private openDetail(company: Company) {
    this.setCurrentCompany(company);
    this.$router.push({ name: ROUTE_COMPANY, params: { id: company.id! } });
  }
}
